import { Center, Image, Stack, Text } from "@mantine/core";
import { FC } from "react";

interface EmptyStateMessageProps {
  image: string;
  message?: string;
}

export const EmptyStateMessage: FC<EmptyStateMessageProps> = ({ image, message }) => {
  const lightImageSrc = `/empty-state/light/${image}.svg`;
  const darkImageSrc = `/empty-state/dark/${image}.svg`;

  return (
    <Stack mt="xl">
      <Center>
        <picture>
          <source srcSet={lightImageSrc} media="(prefers-color-scheme: light)" />
          <source srcSet={darkImageSrc} media="(prefers-color-scheme: dark)" />

          <Image src={lightImageSrc} alt={message} miw="300" w="20%" maw="100%" />
        </picture>
      </Center>

      {message && (
        <Text c="dimmed" fw={600} ta="center" size="xl">
          {message}
        </Text>
      )}
    </Stack>
  );
};
